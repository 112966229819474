export default useHeadersApi => {
    const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');

    const headers = {
        'CSRF-Token': csrfToken,
    };

    return useHeadersApi
        ? new Headers({
              Accept: 'application/json',
              ...headers,
          })
        : headers;
};
