import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// https://reacttraining.com/react-router/web/example/auth-workflow
export const PrivateRoute = ({
    component: Component,
    authenticated,
    componentProps,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={routeProps =>
                authenticated ? (
                    <Component {...routeProps} {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: routeProps.location },
                        }}
                    />
                )
            }
        />
    );
};
PrivateRoute.propTypes = {
    component: PropTypes.func,
    authenticated: PropTypes.bool,
    componentProps: PropTypes.object,
};
// Leuk alternatief, maar nog niet in gebruik: DecisionRoute
// https://stackoverflow.com/questions/43164554/how-to-implement-authenticated-routes-in-react-router-4
export const DecisionRoute = ({
    trueComponent,
    falseComponent,
    decisionFunc,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={decisionFunc() ? trueComponent : falseComponent}
        />
    );
};
DecisionRoute.propTypes = {
    trueComponent: PropTypes.func,
    falseComponent: PropTypes.func,
    decisionFunc: PropTypes.func,
};
