import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import InformationVariantIcon from 'mdi-material-ui/InformationVariant';
import { useHelpContext } from './context';

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
    rightIcon: {
        verticalAlign: 'text-bottom',
        transform: 'scale(0.8)',
    },
});

const OpenTopicLink = ({ topic, className }) => {
    // 3️⃣ Use context instead of custom hook
    const classes = useStyles();
    const { setTopic } = useHelpContext();
    const { t } = useTranslation('help', { useSuspense: false });

    const tooltipText = `${t('help:learn-more-about')} ${t(
        `help:topic-${topic}-title`
    )}`;

    return (
        <span
            role="button"
            className={classnames(classes.root, className)}
            onClick={() => setTopic(topic)}
            title={tooltipText}
        >
            <InformationVariantIcon className={classes.rightIcon} />
        </span>
    );
};
OpenTopicLink.propTypes = {
    className: PropTypes.string,
    topic: PropTypes.string,
};

export default OpenTopicLink;
