import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import theme from '../theme';

const useStyles = makeStyles({
    grid_container: {
        // flex: 1,
        // padding: theme.spacing(4, 4, 4, 4),
    },
});

const unit = theme.spacing(1); // 8

const GridContainer = ({
    spacing = 0,
    justify = 'center',
    alignItems = 'stretch',
    children,
    className,
    ...rest
}) => {
    const classes = useStyles();

    // the div is the negative margin fixer that results into a horizontal scrollbar
    // https://materi padding: spacing * 8 /grid/#negative-margin

    return (
        <div style={{ padding: spacing * unit }}>
            <Grid
                container
                justify={justify}
                alignItems={alignItems}
                spacing={spacing}
                className={classnames(classes.grid_container, className)}
                {...rest}
            >
                {children}
            </Grid>
        </div>
    );
};
GridContainer.propTypes = {
    spacing: PropTypes.number,
    justify: PropTypes.string,
    alignItems: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
};

export default GridContainer;
