import React from 'react';
import PropTypes from 'prop-types';

const { PUBLIC_URL } = process.env;
const httpRe = /^http/;

const Img = ({ src, alt = '', ...rest }) => (
    <img
        src={`${httpRe.test(src) ? '' : PUBLIC_URL}${src}`}
        alt={alt}
        {...rest}
    />
);
Img.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
};

export default Img;
