import { gql } from 'apollo-boost';

export const GET_AUTHENTICATED = gql`
    {
        authenticated
    }
`;

export const GET_IDENTITY = gql`
    {
        identity {
            id_identity
            ref_table
            external_code
            email
            allowed_to_spend
            balance
            accepted_casings_this_year
            accepted_casings_last_year
            waste_saved_in_kg
            acceptance_rate
            ordered_voucher_value_today
        }
    }
`;

export const GET_QUESTIONS = gql`
    query getQuestions($language: String!) {
        questions(language: $language) {
            category
            question
            answer
        }
    }
`;

export const GET_CASINGS = gql`
    {
        casings {
            id
            docket_number
            product_in
            product_out
            matricule
            id_dealer
            id_entity
            id_event_last
            current_status
            current_status2
            date_status
            month
            year
            points
            eligible
            cu_name
            ns_name
            am_name
            id_region
            region
        }
    }
`;

export const GET_ORDERS = gql`
    {
        orders {
            id_order
            date_created
            gcClaimCode
            amount
            currencyCode
            status
        }
    }
`;

export const GET_VARIABLES = gql`
    {
        variables {
            ordered_value_today
        }
    }
`;

export const ORDER_VOUCHER = gql`
    mutation orderVoucher($type: String!, $value: Int!) {
        orderVoucher(type: $type, value: $value) {
            id_order
        }
    }
`;

export const GET_HELLO = gql`
    {
        hello
        posts {
            title
        }
        countries {
            id
            name
        }
        country(id: "NL") {
            id
            name
        }
        author(id: 1) {
            firstName
            posts {
                id
                title
                author {
                    firstName
                    posts {
                        id
                        title
                        author {
                            firstName
                        }
                    }
                }
            }
        }
    }
`;
