import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

// NB: Als de useTranslate functies niet allemaal suspense=false gebruiken
// Dan gaan er 2 zaken kapot:
// 1. Het InView component werkt niet en de chart van EyeCatchingProgressChart gaat meteen lopen
// 2. De leaflet chart krijgt een offset, maar die kan gefixt worden, zie client/src/pages/sections/UserAccount.js
i18n.use(detector)
    .use(XHR)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // ## standard i18next settings ##
        fallbackLng: 'nl',
        load: 'currentOnly',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        whitelist: ['nl', 'fr', 'en', 'de'],
        nonExplicitWhitelist: true, // will pass eg. en-US if finding en in whitelist

        // ## settings for detector ##
        detection: {
            // Na sso, wordt direct naar /[taal]/me geredirect en kunnen we dus path nemen
            // Maar na oproepen van slah (vanuit bookmark) moet er geredirect worden en dan nemen de html tag lang attribute
            // En als die leeg is dan maar de browser taal
            order: ['path', 'htmlTag'],

            // keys or params to lookup language from
            lookupFromPathIndex: 0,

            // only detect languages that are in the whitelist
            checkWhitelist: true,
        },

        // ## settings for XHR ##
        backend: {
            loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}',
        },
    });

export default i18n;
