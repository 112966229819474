import React from 'react';
import PropTypes from 'prop-types';
import UserAccountSection from './sections/UserAccount';
// import BrandedArticlesSection from './sections/BrandedArticles';

import AgcodSection from './sections/Agcod';
import BlueBarSection from './sections/BlueBar';

const HomeAuthenticated = ({ setVersion }) => {
    return (
        <>
            <UserAccountSection />
            <BlueBarSection translationKey="citation-in-blue-bar1" />
            <AgcodSection setVersion={setVersion} />
            {/* <BlueBarSection
                translationKey="citation-in-blue-bar2"
                spaceBottom={true}
            />
            <BrandedArticlesSection /> */}
        </>
    );
};
HomeAuthenticated.propTypes = {
    setVersion: PropTypes.func,
};
export default HomeAuthenticated;
