import React, { Suspense, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import './i18n';
import { withProvider } from './context';
import { CookieInfo, ErrorBoundary, Header, Footer } from './components';
import { Routes } from './pages';
import theme from './theme';
import {
    HelpDrawer,
    Snackbar,
    useHelpContext,
    useSnackbarContext,
} from './help';

// https://stackoverflow.com/questions/52472372/responsive-typography-in-material-ui
// breakpoints: {xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920}
const useStyles = makeStyles({
    '@global': {
        html: {
            fontSize: 16,
            // voorlopig geen responsive font en anders onderstaande aan
            // [theme.breakpoints.up('sm')]: {
            //     fontSize: 17,
            // },
            // [theme.breakpoints.up('md')]: {
            //     fontSize: 18,
            // },
            // [theme.breakpoints.up('lg')]: {
            //     fontSize: 19,
            // },
            // [theme.breakpoints.up('xl')]: {
            //     fontSize: 19,
            // },
        },
    },
    site: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        '& .loading': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.contrastText,
            minHeight: '100vh',
        },
    },
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 100,
        borderBottom: `${theme.HEADER_SEPARATOR_HEIGHT}px solid ${theme.palette.primary.main}`,
    },
    main: {
        flex: 1,
        padding: 0,
        marginTop: theme.HEADER_HEIGHT,
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.HEADER_HEIGHT_MOBILE,
        },
        // lichtgrijze zodat papers mooi afsteken
        backgroundColor: theme.palette.background.default,
        // Each page is rendered inside main. It may have title, cards and more
        // They will be horizontally centered by default.
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 99,

        '&> section': {
            // zorgen dat het witte gedeelte met flex: 1 de resterende hoogte kan pakken
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // zorgen dat per section ook absoluut gepositioneerd kan worden
            position: 'relative',

            // https://stackoverflow.com/questions/22247178/html-sections-100-height-of-viewport
            // minHeight: '100vh',
        },
    },
});

const store = {};

const Loader = () => <div className="loading">loading...</div>;

const App = () => {
    const classes = useStyles();
    const [version, setVersion] = useState(Date.now());
    // Suspense moet hoger gebruikt worden dan het hoogste component
    // waar useTranslate in gebruikt wordt
    return (
        <useSnackbarContext.Provider>
            <useHelpContext.Provider>
                <div className={classes.site}>
                    <Suspense fallback={<Loader />}>
                        <Header className={classes.header} version={version} />
                        <main className={classes.main}>
                            <ErrorBoundary>
                                <Routes setVersion={setVersion} />
                            </ErrorBoundary>
                        </main>
                        <Footer />
                        <HelpDrawer />
                        <Snackbar />
                        <CookieInfo />
                    </Suspense>
                </div>
            </useHelpContext.Provider>
        </useSnackbarContext.Provider>
    );
};

export default withProvider(store, {
    persist: window.localStorage,
    persistKey: 'site',
})(App);
