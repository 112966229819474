import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWindowScroll } from 'react-use';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { Img, P } from './';
import { useIdentity } from '../hooks';
import { OpenFaqButton } from '../help';
import MainMenu from './MainMenu';
import theme from '../theme';

const useStyles = makeStyles({
    header: {
        transition: 'height .25s ease',
        display: 'flex',
        alignItems: 'stretch',
        backgroundColor: theme.palette.background.default,
        height: theme.HEADER_HEIGHT,
        '&.reduced': {
            height: theme.HEADER_HEIGHT_MOBILE,
        },
        [theme.breakpoints.down('xs')]: {
            height: theme.HEADER_HEIGHT_MOBILE,
        },
    },
    left: {},
    middle: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    help: {
        display: 'flex',
        alignItems: 'center',
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
        },
        alignItems: 'flex-end',
    },
    topright: {
        flex: 1,
        transition: 'transform .25s ease',
        transform: 'scale(1)',
        marginRight: 5,
        '&.reduced': {
            marginRight: 0,
            transform: 'scale(0.8)',
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            transform: 'scale(0.8)',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    logo: {
        transition: 'height .25s ease',
        height: theme.LOGO_HEIGHT,
        '&.reduced': {
            height: theme.LOGO_HEIGHT_MOBILE,
            marginTop: theme.HEADER_PADDING_MOBILE,
            marginBottom: theme.HEADER_PADDING_MOBILE,
        },
        [theme.breakpoints.down('xs')]: {
            height: theme.LOGO_HEIGHT_MOBILE,
            marginTop: theme.HEADER_PADDING_MOBILE,
            marginBottom: theme.HEADER_PADDING_MOBILE,
        },
        marginTop: theme.HEADER_PADDING,
        marginBottom: theme.HEADER_PADDING,
        // filter:
        //     theme.palette.type === 'dark'
        //         ? 'none ' // maakt hem helemaal wit: 'brightness(0) invert(1) '
        //         : 'none',
    },
    button: {
        transition: 'opacity .25s linear',
        '&.important': {
            opacity: 1,
        },
        '&.reduced': {
            opacity: 0,
        },
    },
});

const Header = ({ className, version }) => {
    const classes = useStyles();
    const { identity } = useIdentity(version, true);
    const { t } = useTranslation('common', { useSuspense: false });
    const { y } = useWindowScroll();

    const atTop = y < 1;

    return (
        <header
            className={classnames(className, classes.header, {
                reduced: !atTop,
            })}
        >
            {identity ? <MainMenu atTop={atTop} /> : null}
            <div className={classes.middle}>
                <Img
                    className={classnames(classes.logo, {
                        reduced: !atTop,
                    })}
                    src={
                        theme.palette.type === 'dark'
                            ? '/media/DriveandSave-logo-C2C2C2.svg'
                            : '/media/DriveandSave-logo-27509B.svg'
                    }
                    alt={t('ds-logo-alt')}
                    title={t('ds-logo-alt')}
                />
            </div>
            <div className={classes.help}>
                <OpenFaqButton />
            </div>
            <div className={classes.right}>
                {identity ? (
                    <div
                        className={classnames(classes.topright, {
                            reduced: !atTop,
                        })}
                    >
                        <div>
                            <P color="textPrimary">
                                {identity.balance} TruckMiles
                            </P>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                code &gt; {identity.external_code}
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </div>
        </header>
    );
};
Header.propTypes = {
    className: PropTypes.string,
    version: PropTypes.number,
};

export default Header;
