import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H5, H6, Emoji } from '../components';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static propTypes = {
        children: PropTypes.any,
    };

    componentDidCatch(error, info) {
        // logErrorToMyService(error, info);
        this.setState({ hasError: true, error, info });
    }

    render() {
        if (!this.state.hasError) return this.props.children;
        // render custom fallback UI
        return (
            <section>
                <H5>
                    Er ging helaas iets mis <Emoji symbol="😞" />
                </H5>
                <pre>{this.state.error.message}</pre>
                <H6>
                    Component stack <Emoji symbol="🤓" />
                </H6>
                <pre>{this.state.info.componentStack}</pre>
                <H6>
                    Error stack <Emoji symbol="🤓" />
                </H6>
                <pre>{this.state.error.stack}</pre>
            </section>
        );
    }
}

export default ErrorBoundary;
