import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { P } from '../../components';
import theme from '../../theme';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: 100,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontWeight: 'bolder',
        fontStyle: 'italic',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            padding: theme.spacing(0, 4, 0, 4),
        },
    },
    spaceBottom: {
        margin: theme.spacing(0, 0, 4, 0),
    },
});

const BlueBar = ({ translationKey, spaceBottom }) => {
    const classes = useStyles();
    const { t } = useTranslation('common', { useSuspense: false });
    return (
        <div
            className={classnames(
                classes.root,
                spaceBottom && classes.spaceBottom
            )}
        >
            <P align="center">{t(translationKey)}</P>
        </div>
    );
};
BlueBar.propTypes = {
    translationKey: PropTypes.string,
    spaceBottom: PropTypes.bool,
};

export default BlueBar;
