import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { H5, Md, MySnackbarContent } from '../../components';
import { fetchJson } from '../../components/ra/fetch';
import getCustomHeaders from '../../utils/customHeaders';

const useStyles = makeStyles({
    card: {
        margin: 'auto 0px',
        maxWidth: 400,
    },
    spacer: {
        flex: 1,
        textAlign: 'center',
    },
});

const LoginSection = () => {
    const classes = useStyles();
    const [values, setValues] = useState({ email: '' });
    const [submitState, setSubmitState] = useState('idle');
    const [message, setMessage] = useState(null);
    const match = useRouteMatch();
    const { t } = useTranslation(['common', 'exception'], {
        useSuspense: false,
    });

    const handleInputChange = e => {
        const { name, value } = e.target;
        setMessage(null);
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitState('submitting');

        try {
            await fetchJson('/api/auth/request', {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    email: values.email,
                    language: match.params.lng,
                }),
                headers: getCustomHeaders(true),
            });

            setSubmitState('success');
        } catch (e) {
            setMessage(e.json.error.message);
            setSubmitState('failed');
        }
    };

    const isLoading = submitState === 'submitting';

    return (
        <Card className={classes.card}>
            <form onSubmit={handleSubmit}>
                {submitState === 'success' ? (
                    <CardContent>
                        <H5>{t('common:login-dealer-title')}</H5>
                        <Md>{t('common:login-dealer-success')}</Md>
                    </CardContent>
                ) : (
                    <CardContent>
                        <H5>{t('common:login-dealer-title')}</H5>
                        <Md>{t('common:login-dealer-headsup')}</Md>
                        <TextField
                            className={classes.textField}
                            margin="normal"
                            fullWidth
                            label={t('common:login-dealer-email')}
                            name="email"
                            disabled={isLoading}
                            onChange={handleInputChange}
                            value={values.email}
                        />
                    </CardContent>
                )}
                {message && submitState !== 'success' && (
                    <CardContent>
                        <MySnackbarContent
                            variant="warning"
                            message={t(`exception:${message}`)}
                        />
                    </CardContent>
                )}
                {submitState !== 'success' && (
                    <CardActions>
                        <Button
                            variant="contained"
                            color="grey"
                            to="/"
                            component={Link}
                        >
                            {t('common:go-back')}
                        </Button>
                        <span className={classes.spacer} />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {isLoading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {t('common:login-dealer-send')}
                        </Button>
                    </CardActions>
                )}
            </form>
        </Card>
    );
};

export default LoginSection;
