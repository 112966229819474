import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import { Button, Paper } from '@material-ui/core';
import ChevronDownIcon from 'mdi-material-ui/ChevronDown';
import ChevronUpIcon from 'mdi-material-ui/ChevronUp';
import { GcClaimCode } from '../../components';
import { useOrders } from '../../hooks';
import { dateFormat } from '../../utils/date';

const useStyles = makeStyles(theme => ({
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        '& .maxWidth': {
            maxWidth: 600,
        },
    },
}));

const OrderHistory = ({ shop }) => {
    const classes = useStyles();
    const { t } = useTranslation('history', { useSuspense: false });
    const [visible, setVisible] = useState(false);
    const handleClickToggle = event => {
        event.stopPropagation();
        setVisible(!visible);
    };
    const orders = useOrders();

    const ordersData = orders.loading ? [] : orders.data;

    const columns = [
        {
            field: 'amount',
            render: rowData => `${rowData.amount} ${rowData.currencyCode}`,
            width: 100,
            cellStyle: {
                fontWeight: 'bold',
                textAlign: 'right',
            },
        },
        {
            field: 'gcClaimCode',
            render: GcClaimCode,
            width: 240,
        },
        {
            field: 'date_created',
            render: rowData =>
                dateFormat(rowData.date_created, 'd MMM yyyy, HH:mm'),
            cellStyle: {
                textAlign: 'right',
            },
        },
    ];

    console.log(orders);

    return (
        <Paper square elevation={0} className={classes.paperContainer}>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleClickToggle}
                endIcon={visible ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
                {t(`${shop}-history-title`)}
            </Button>
            <div className="maxWidth">
                {visible && (
                    <MaterialTable
                        columns={columns}
                        data={ordersData}
                        options={{
                            showTitle: false,
                            search: false,
                            toolbar: false,
                            header: false,
                            filtering: false,
                            tableLayout: 'fixed',
                            cellStyle: { padding: 5 },
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: t(
                                    'history:emptyDataSourceMessage'
                                ),
                            },
                        }}
                    />
                )}
            </div>
        </Paper>
    );
};
OrderHistory.propTypes = {};

export default OrderHistory;
