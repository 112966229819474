import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { H4, Md } from '../components';

const Content = ({ title, body }) => (
    <Fragment>
        <H4>
            <Markdown>{title}</Markdown>
        </H4>
        <Md>{body}</Md>
    </Fragment>
);
Content.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
};
export default Content;
