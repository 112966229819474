import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@material-ui/core';
import HelpCircleOutlineIcon from 'mdi-material-ui/HelpCircleOutline';
import theme from '../theme';
import { useHelpContext } from './context';

const color = theme.palette.type === 'dark' ? 'primary' : 'secondary';

const OpenFaqButton = ({ className }) => {
    // 3️⃣ Use context instead of custom hook
    const { setTopic } = useHelpContext();
    const { t } = useTranslation(['common', 'pages'], { useSuspense: true });
    const handleClick = useCallback(() => setTopic('faq'), []);
    return (
        <Tooltip title={t('common:faq-title')} arrow>
            <Button
                size="small"
                color={color}
                className={className}
                onClick={handleClick}
            >
                <HelpCircleOutlineIcon />
            </Button>
        </Tooltip>
    );
};
OpenFaqButton.propTypes = {
    className: PropTypes.string,
};

export default OpenFaqButton;
