import { useState, useEffect } from 'react';
import createUseContext from 'constate';

// 1️⃣ Create a custom hook as usual
function useHelp() {
    const [topic, setTopic] = useState(0);

    return { topic, setTopic };
}

// 2️⃣ Wrap your hook with the createUseContext factory
export const useHelpContext = createUseContext(useHelp);

// 3️⃣ Use context instead of custom hook
// see: ./client/src/help/OpenTopicLink.js

// 4️⃣ Use context in other components
// see: ./client/src/help/HelpDrawer.js

// 5️⃣ Wrap your components with Provider
// see:  ./client/src/App.js

// 1️⃣ Create a custom hook as usual
function useSnackbar() {
    // gebaseerd op https://medium.com/swlh/snackbars-in-react-an-exercise-in-hooks-and-context-299b43fd2a2b?
    const [alerts, setAlerts] = useState([]);

    const activeAlertIds = alerts.join(',');
    useEffect(() => {
        if (activeAlertIds.length > 0) {
            const timer = setTimeout(
                () => setAlerts(alerts => alerts.slice(0, alerts.length - 1)),
                10000
            );
            return () => clearTimeout(timer);
        }
    }, [activeAlertIds]);

    return { alerts, setAlerts };
}

// 2️⃣ Wrap your hook with the createUseContext factory
export const useSnackbarContext = createUseContext(useSnackbar);
