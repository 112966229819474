import { createMuiTheme } from '@material-ui/core/styles';

const constants = {
    LOGO_HEIGHT: 60,
    LOGO_HEIGHT_MOBILE: 20,
    HEADER_SEPARATOR_HEIGHT: 4,
    HEADER_PADDING: 20,
    HEADER_PADDING_MOBILE: 10,
};

constants.HEADER_HEIGHT =
    constants.LOGO_HEIGHT +
    2 * constants.HEADER_PADDING +
    constants.HEADER_SEPARATOR_HEIGHT;

constants.HEADER_HEIGHT_MOBILE =
    constants.LOGO_HEIGHT_MOBILE +
    2 * constants.HEADER_PADDING_MOBILE +
    constants.HEADER_SEPARATOR_HEIGHT;

const styleguide = {
    color: {
        // Primary colours
        blue: '#27509b',
        yellow: '#fce500',
        danger: '#d9534f',
        darkGrey: '#a9a9a9',
        darkerGrey: '#262626',
        disabledGrey: '#a9acaf',
        textColor: '#404040',
    },
};

const headerStyles = {
    // marginTop: '1rem',
    // marginBottom: '1rem',
    textTransform: 'uppercase',
    fontWeight: 800, // extra bold
    fontStyle: 'italic',
};

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: 'Open Sans',
        h1: { ...headerStyles },
        h2: { ...headerStyles },
        h3: { ...headerStyles },
        h4: { ...headerStyles },
        h5: { ...headerStyles },
        h6: { ...headerStyles },
    },
    palette: {
        type: 'light',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: styleguide.color.yellow,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            // contrastText: styleguide.color.textColor,
        },
        secondary: {
            main: styleguide.color.blue,
            // contrastText: 'white',
        },
        error: {
            main: styleguide.color.danger,
            // contrastText: styleguide.color.textColor,
        },
        // text: {
        //     primary: styleguide.color.textColor,
        //     secondary: styleguide.color.darkGrey,
        //     disabled: styleguide.color.disabledGrey,
        //     hint: styleguide.color.disabledGrey,
        // },
    },
    overrides: {
        // Playbook: https://brandscenter.michelingroup.com/michelin/en/projects/digital/websites/graphic-and-design/action-buttons/
        MuiButton: {
            root: {
                minWidth: 'unset',
            },
            contained: {
                boxShadow: 'none',
                borderRadius: 0,
            },
            label: {
                fontWeight: 'bold',
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: styleguide.color.darkGrey,
                    color: 'white',
                },
            },
            containedSecondary: {
                '&:hover': {
                    backgroundColor: styleguide.color.darkGrey,
                    color: 'white',
                },
            },
        },
    },
    styleguide,
    ...constants,
});

export default theme;
