import { makeStyles } from '@material-ui/styles';
import theme from '../theme';

export default makeStyles({
    overlay: {
        position: 'absolute',
        maxHeight: '50vh',
        // border: '1px solid red',
        top: theme.HEADER_HEIGHT / 2,
        [theme.breakpoints.down('xs')]: {
            top: theme.HEADER_HEIGHT_MOBILE / 2,
        },
        zIndex: 400, // minder dan 400 raakt het achter de leaflet map!
        color: theme.palette.common.white,
        whiteSpace: 'nowrap',
        '& h2, h3, h4, h5': {
            lineHeight: 1.5,
        },
        '& h2': {
            fontSize: '5vw',
        },
        '& h3': {
            fontSize: '4.5vw',
        },
        '& h4': {
            fontSize: '4vw',
        },
        '& h5': {
            fontSize: '3.5vw',
        },
        '& .separator': {
            borderBottom: `${theme.HEADER_SEPARATOR_HEIGHT}px solid ${theme.palette.primary.main}`,
        },
        '& .contrast': {
            padding: '0px 2vw 0px 1vw',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
    },
    stretchedCard: {
        // stretch to its container
        height: '100%',
        // CardAction stick to bottom
        // https://stackoverflow.com/questions/57818778/how-to-make-material-ui-cardactions-always-stick-to-the-bottom-of-parent
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
});
