import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { H5, Md, MySnackbarContent /*getCustomLink*/ } from '../../components';

const useStyles = makeStyles({
    card: {
        margin: 'auto 0px',
        maxWidth: 400,
    },
    spacer: {
        flex: 1,
    },
});

const { REACT_APP_IDP_LOGIN_URL } = process.env;

const LoginSection = () => {
    const classes = useStyles();
    const match = useRouteMatch();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        setMessage(parsed.message || null);
    }, [window.location]);
    const { t } = useTranslation(['common', 'exception'], {
        useSuspense: false,
    });

    return (
        <>
            <Card className={classes.card}>
                {message && (
                    <CardContent>
                        <MySnackbarContent
                            variant="warning"
                            message={t(`exception:${message}`)}
                        />
                    </CardContent>
                )}
                <CardContent>
                    <H5>{t('common:login-title')}</H5>
                    <Md>{t('common:login-body')}</Md>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        // MOCKED LOGIN     > href={getCustomLink('/sso/login/mocked-sso-callback')}
                        // SP-initiated SSO > href={getCustomLink('/sso/login')}
                        href={`${REACT_APP_IDP_LOGIN_URL}/portal/s/loyalty-programs`}
                        component="a"
                    >
                        {t('common:login-as-cu')}
                    </Button>
                    <span className={classes.spacer} />
                    <Button
                        variant="contained"
                        color="primary"
                        to={`${match.url}/dealer`}
                        component={Link}
                    >
                        {t('common:login-as-ns')}
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default LoginSection;
