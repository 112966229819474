import React from 'react';
import useInView from 'use-in-view';

const InView = ({ children, ...rest }) => {
    const [ref, inView] = useInView(30);

    return (
        <div ref={ref} {...rest}>
            {inView && children}
        </div>
    );
};

export default InView;
