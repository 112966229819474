// react etc
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// mui etc
import { makeStyles } from '@material-ui/styles';
import HelpIcon from 'mdi-material-ui/HelpCircleOutline';
import ArrowCollapseRightIcon from 'mdi-material-ui/ArrowCollapseRight';
import { IconButton, Drawer } from '@material-ui/core';
// ra etc
// own etc
import { useHelpContext } from './context';
import Content from './Content';
import FaqContent from './FaqContent';
import OpenFaqButton from './OpenFaqButton';

const useStyles = makeStyles({
    Drawer: {},
    Drawer_paper: {
        width: ({ topic }) => (topic === 'faq' ? '80%' : 370),
        maxWidth: '80%',
        lineHeight: '140%',
    },
    container: {
        padding: 36,
        '& > *': {
            marginBottom: 8,
        },
    },
    DrawerHelpIconClipper: {
        position: 'absolute',
        width: '80%',
        height: '80%',
        overflow: 'hidden',
        right: 0,
        top: 0,
        zIndex: 1000,
        pointerEvents: 'none',
        '& .icon': {
            // meer specifiek maken dan .MuiIconRoot
            // https://stackoverflow.com/questions/56929702/material-ui-v4-makestyles-exported-from-a-single-file-doesnt-retain-the-styles
            position: 'absolute',
            top: '-5%',
            right: '-20%',
            opacity: 0.05,
            width: '100%',
            height: 'auto',
        },
    },
    internalNotice: {
        fontSize: '70%',
        fontStyle: 'italic',
    },
});

const HelpDrawer = () => {
    const { topic, setTopic } = useHelpContext();
    const classes = useStyles({ topic });
    const { t, i18n } = useTranslation('help', { useSuspense: false });

    const handleDrawerClose = useCallback(() => {
        setTopic('');
    }, []);

    const localOpen = !!topic;

    const topicExists =
        topic === 'faq' || i18n.exists(`help:topic-${topic}-body`);

    const title = t([`help:topic-${topic}-title`, `help:topic-404-title`]);
    const body = t([`help:topic-${topic}-body`, `help:topic-404-body`]);

    return (
        <Drawer
            anchor="right"
            open={localOpen}
            onClose={handleDrawerClose}
            classes={{
                paper: classes.Drawer_paper,
            }}
        >
            <div>
                <IconButton onClick={handleDrawerClose}>
                    <ArrowCollapseRightIcon />
                </IconButton>
            </div>
            <div className={classes.container}>
                <div className={classes.DrawerHelpIconClipper}>
                    <HelpIcon className="icon" />
                </div>
                {topic === 'faq' ? (
                    <FaqContent />
                ) : (
                    <>
                        <OpenFaqButton />
                        <Content title={title} body={body} />
                    </>
                )}
                {!topicExists && (
                    <span className={classes.internalNotice}>
                        topic: {topic}
                    </span>
                )}
            </div>
        </Drawer>
    );
};

export default HelpDrawer;
