import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from 'mdi-material-ui/ContentCopy';
import copy from 'copy-to-clipboard';

const useStyles = makeStyles({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: 8,
        flex: 1,
        fontFamily: 'Courier',
        fontSize: '3vw',
        fontWeight: 900,
    },
    iconButton: {
        padding: 10,
    },
});

const DsCode = ({ code }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <InputBase className={classes.input} value={code} readOnly={true} />
            <IconButton
                className={classes.iconButton}
                aria-label="Copy"
                onClick={() => copy(code)}
            >
                <ContentCopyIcon />
            </IconButton>
        </Paper>
    );
};
DsCode.propTypes = {
    code: PropTypes.string,
};

export default DsCode;
