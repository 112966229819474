import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import ChevronDoubleLeft from 'mdi-material-ui/ChevronDoubleLeft';
import ChevronRight from 'mdi-material-ui/ChevronRight';
import ChevronDoubleRight from 'mdi-material-ui/ChevronDoubleRight';

import backgroundImage from './Subtle-Prism.svg';
// https://www.svgbackgrounds.com/#subtle-prism (#000000, #27509B, scale/angle 0 en opacity iets van 10%)

import { H5, Md, ImgT } from '../../components';
import { OpenTopicLink, OpenTopicButton } from '../../help';
import {
    useGlobalStyles,
    useIdentity,
    useOrderVoucher,
    useVariables,
} from '../../hooks';
import StepIcon from './StepIcon'; //  a copy of @material-ui/core/StepIcon but with another CheckIcon for the completed step

const useStyles = makeStyles(theme => ({
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        '& .maxWidth': {
            maxWidth: 600,
        },
    },
    containerWithRightAlignedItems: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },

    Slider_root: {
        padding: 0,
    },

    card: {
        display: 'flex',
        backgroundImage: `url(${backgroundImage})`,
        '& .contrast-text': {
            color: theme.palette.secondary.contrastText,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
    },
    cover: {
        flex: 1,
        '& img': {
            width: '100%',
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    StepIcon_completed: {
        color: `${theme.palette.secondary.main} !important`,
    },
}));
const MIN_VOUCHER_VALUE = 25;
const START_VOUCHER_VALUE = 25;
const MAX_VOUCHER_VALUE = 500;
const POINT_VALUE_IN_EUR = 0.05;
const DELTA_VALUE_SMALL = 25;
const DELTA_VALUE_LARGE = 100;

const DAILY_GLOBAL_LIMIT = 20000;
const DAILY_ACCOUNT_LIMIT = 500;

const round = value =>
    DELTA_VALUE_SMALL * Math.floor(value / DELTA_VALUE_SMALL);

// stipje elke 100 en komt overeen met de stappen van fast backward/forward
const marks = [...new Array(MAX_VOUCHER_VALUE / DELTA_VALUE_LARGE)]
    .map((item, index) => round(DELTA_VALUE_LARGE * (index + 1)))
    .map(value => ({
        value,
        label: ' ', // zonder label plakt slider niet meer tegen card... `€ ${value},-`,
    }));

function valuetext(value) {
    return `€ ${value.toLocaleString('nl')}`;
}

const { AGCOD_ENV } = process.env;

const OrderVoucher = ({ shop, setVersion }) => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [voucherValue, setVoucherValue] = useState(START_VOUCHER_VALUE);
    const { identity } = useIdentity();
    const [orderVoucher, { loading: orderingVoucher }] = useOrderVoucher({
        onCompleted: (/*data*/) => {
            setVersion(Date.now());
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        },
    });
    const { ordered_value_today = 0 } = useVariables();

    const { t } = useTranslation(['order'], {
        useSuspense: false,
    });

    if (!identity) return null;

    const handleNext = () => {
        if (activeStep === 2) {
            orderVoucher({
                variables: { type: 'AGCOD-EU', value: voucherValue },
            });
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setVoucherValue(START_VOUCHER_VALUE);
        setActiveStep(0);
    };

    const handleSliderChange = (event, value) => {
        const newValue = round(verifyAndAlterEuroValue(round(value)));

        setVoucherValue(newValue);
    };

    const makeHandleValueClick = delta => () => {
        let newValue = round(voucherValue) + delta;
        if (newValue > MAX_VOUCHER_VALUE) newValue = MAX_VOUCHER_VALUE;
        else if (newValue < MIN_VOUCHER_VALUE) newValue = MIN_VOUCHER_VALUE;

        newValue = round(verifyAndAlterEuroValue(newValue));

        setVoucherValue(newValue);
    };

    const verifyAndAlterEuroValue = newEuroValue => {
        // Test of je niet te hoog uitkomt
        const truckMilesPrice = Math.round(newEuroValue / POINT_VALUE_IN_EUR);

        if (truckMilesPrice > identity.balance) {
            // Voor als we zelfs hier willen afronden op die 25 eur
            // newEuroValue = round(identity.balance * POINT_VALUE_IN_EUR);
            // Voorlopig rond ik hem zo af op de eur bij je max
            newEuroValue = Math.floor(identity.balance * POINT_VALUE_IN_EUR);
        } else if (
            newEuroValue + identity.ordered_voucher_value_today >
            DAILY_ACCOUNT_LIMIT
        ) {
            // dan zetten we de voucher waarde in euro gelijk aan het restant
            // die de gebruiker nog over heeft voor die dag
            newEuroValue =
                DAILY_ACCOUNT_LIMIT - identity.ordered_voucher_value_today;
        }

        return newEuroValue;
    };

    const minReached = voucherValue <= MIN_VOUCHER_VALUE;
    const maxReached = voucherValue >= MAX_VOUCHER_VALUE;
    const truckMilesPrice = Math.round(voucherValue / POINT_VALUE_IN_EUR);
    const truckMilesPriceToGo =
        Math.round(voucherValue / POINT_VALUE_IN_EUR) - identity.balance;
    const maximumVoucherValue = Math.min(
        Math.floor(identity.balance * POINT_VALUE_IN_EUR),
        DAILY_ACCOUNT_LIMIT - identity.ordered_voucher_value_today
    );
    const i18nVariables = {
        type: shop === 'agcod' ? 'Amazon' : 'brand addition',
        voucherValue,
        truckMilesPrice: truckMilesPrice.toLocaleString('nl'),
        truckMilesPriceToGo,
        maximumVoucherValue,
        MIN_VOUCHER_VALUE,
        MAX_VOUCHER_VALUE,
    };

    const StepIconProps = {
        classes: {
            completed: classes.StepIcon_completed,
        },
    };

    const allowedToSpend = identity.allowed_to_spend;
    const enoughPointsForPurchase = maximumVoucherValue >= MIN_VOUCHER_VALUE;
    const purchaseDisabled = !enoughPointsForPurchase || !allowedToSpend;
    const shopIsDisabled = !parseInt(t(`${shop}-active`));

    const globalDisabled =
        shopIsDisabled ||
        voucherValue <= 0 ||
        purchaseDisabled ||
        orderingVoucher ||
        ordered_value_today > DAILY_GLOBAL_LIMIT;

    const messageUnderneathVoucher = shopIsDisabled ? (
        <Md inline={true}>{t(`${shop}-inactive-message`, i18nVariables)}</Md>
    ) : ordered_value_today >= DAILY_GLOBAL_LIMIT ? (
        <Md inline={true}>{t(`${shop}-limit-message`, i18nVariables)}</Md>
    ) : identity.ordered_voucher_value_today >= DAILY_ACCOUNT_LIMIT ? (
        <Md inline={true}>{t(`${shop}-limit-message`, i18nVariables)}</Md>
    ) : allowedToSpend ? (
        enoughPointsForPurchase ? (
            <>
                <Md>{t('step-1-body', i18nVariables)}</Md>
                <Md>{t('step-1-body-limit', i18nVariables)}</Md>
            </>
        ) : (
            <>
                <Md inline={true}>
                    {t('step-1-body-not-enough-points', i18nVariables)}
                </Md>
                <OpenTopicLink topic="howto-earn-points" />
            </>
        )
    ) : (
        <>
            <Md inline={true}>
                {t('step-1-body-not-allowed-to-spend', i18nVariables)}
            </Md>
            <OpenTopicLink topic="not-allowed-to-spend" />
        </>
    );

    console.log('render OrderVoucher', {
        minReached,
        maxReached,
        truckMilesPrice,
        truckMilesPriceToGo,
        i18nVariables,
        AGCOD_ENV,
        voucherValue,
        enoughPointsForPurchase,
        orderingVoucher,
        purchaseDisabled,
        allowedToSpend,
        shopIsDisabled,
    });

    return (
        <Paper square elevation={0} className={classes.paperContainer}>
            <div className="maxWidth">
                <H5>{t(`${shop}-intro-title`)}</H5>
                <Md>{t(`${shop}-intro-body`)}</Md>
                <div className={classes.containerWithRightAlignedItems}>
                    <OpenTopicButton topic="amazoncard" />
                </div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key="step-1">
                        <StepLabel
                            StepIconComponent={StepIcon}
                            StepIconProps={StepIconProps}
                        >
                            {t('step-1-title')}
                        </StepLabel>
                        <StepContent>
                            <Card
                                className={classnames(
                                    classes.card,
                                    purchaseDisabled && globalClasses.disabled
                                )}
                            >
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <H5
                                            align="left"
                                            className="contrast-text"
                                        >
                                            {valuetext(voucherValue)}
                                        </H5>
                                        <Typography
                                            variant="subtitle2"
                                            className="contrast-text"
                                        >
                                            {t('value-subtitle', i18nVariables)}
                                        </Typography>
                                    </CardContent>
                                    <div className={classes.controls}>
                                        <IconButton
                                            color="primary"
                                            onClick={makeHandleValueClick(
                                                -DELTA_VALUE_LARGE
                                            )}
                                            disabled={minReached}
                                        >
                                            <ChevronDoubleLeft />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={makeHandleValueClick(
                                                -DELTA_VALUE_SMALL
                                            )}
                                            disabled={minReached}
                                        >
                                            <ChevronLeft />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={makeHandleValueClick(
                                                DELTA_VALUE_SMALL
                                            )}
                                            disabled={maxReached}
                                        >
                                            <ChevronRight />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={makeHandleValueClick(
                                                DELTA_VALUE_LARGE
                                            )}
                                            disabled={maxReached}
                                        >
                                            <ChevronDoubleRight />
                                        </IconButton>
                                    </div>
                                    <Slider
                                        classes={{
                                            root: classes.Slider_root,
                                        }}
                                        min={MIN_VOUCHER_VALUE}
                                        max={MAX_VOUCHER_VALUE}
                                        value={voucherValue}
                                        marks={marks}
                                        aria-labelledby="discrete-slider-restrict"
                                        onChange={handleSliderChange}
                                    />
                                </div>
                                <CardContent className={classes.cover}>
                                    <ImgT src={`order:${shop}-logo`} />
                                </CardContent>
                            </Card>

                            <div className={classes.actionsContainer}>
                                {messageUnderneathVoucher}
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('step-back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={globalDisabled}
                                    >
                                        {t('step-next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="step-2">
                        <StepLabel
                            StepIconComponent={StepIcon}
                            StepIconProps={StepIconProps}
                        >
                            {t('step-2-title')}
                        </StepLabel>
                        <StepContent>
                            <Md>
                                {t(
                                    `step-2-body-${identity.ref_table}`,
                                    i18nVariables
                                )}
                            </Md>
                            <TextField
                                className={classes.textField}
                                margin="normal"
                                fullWidth
                                label={t('common:login-dealer-email')}
                                name="email"
                                disabled={true}
                                value={identity.email}
                            />
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('step-back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={globalDisabled}
                                    >
                                        {t('step-next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key="step-3">
                        <StepLabel
                            StepIconComponent={StepIcon}
                            StepIconProps={StepIconProps}
                        >
                            {t('step-3-title')}
                        </StepLabel>
                        <StepContent>
                            <Md>{t('step-3-body', i18nVariables)}</Md>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('step-back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        startIcon={
                                            orderingVoucher ? (
                                                <CircularProgress color="secondary" />
                                            ) : (
                                                undefined
                                            )
                                        }
                                        disabled={globalDisabled}
                                    >
                                        {t('step-finish')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep > 2 && (
                    <>
                        <Md>{t(`${shop}-finished`, i18nVariables)}</Md>
                        <Button
                            onClick={handleReset}
                            className={classes.button}
                        >
                            {t('step-reset')}
                        </Button>
                    </>
                )}
            </div>
        </Paper>
    );
};
OrderVoucher.propTypes = {
    shop: PropTypes.oneOf(['agcod', 'michart']),
    setVersion: PropTypes.func,
};

export default OrderVoucher;
