// react etc
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// mui etc
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { green, amber } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
// ra etc
// own etc
import { useSnackbarContext } from './context';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classnames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon
                        className={classnames(
                            classes.icon,
                            classes.iconVariant
                        )}
                    />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
        .isRequired,
};

const SnackbarComponent = () => {
    const { alerts } = useSnackbarContext();

    const open = !!alerts.length;

    return alerts.map(alert => {
        const numberOfWords = alert.split(' ').length;
        // Normale leessnelheid is ~ 200 woorden per minuut = 3,33 woorden/s
        // Ik geef hier wat meer tijd, omdat het foutmeldingen zijn:
        // -> 1 seconde per woord (maar minimaal 6 seconden)
        const autoHideDuration = Math.max(numberOfWords * 1000, 6000);

        // Helaas heeft bovenstaande geen effect, omdat de alerts worden verwijderd
        // door de useSnackbar in client/src/help/context.js
        // dus ik zet hem daar nu even op 10 seconden...

        return (
            <Snackbar
                key={alert}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={autoHideDuration}
                // onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    // onClose={handleClose}
                    variant="error"
                    message={alert}
                />
            </Snackbar>
        );
    });
};

export default SnackbarComponent;
