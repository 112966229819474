import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const httpRe = /^http/;

const ImgT = ({ src, alt = '', ...rest }) => {
    const { t: translate } = useTranslation('hero', { useSuspense: false });

    const translatedSrc = translate(src);

    // Omdat voorlopig useTranslate niet suspend, moet src pas gevuld worden
    // als de src (bijvoorbeeld hero:img-bibendum-gifts) vertaald is naar http://...
    return (
        <img
            src={httpRe.test(translatedSrc) ? translatedSrc : ''}
            alt={alt}
            {...rest}
        />
    );
};
ImgT.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
};

export default ImgT;
