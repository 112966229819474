export { default as HomeAnonymous } from './HomeAnonymous';
export { default as HomeAuthenticated } from './HomeAuthenticated';
export { default as HomeDealerLogin } from './HomeDealerLogin';
export { default as Routes } from './Routes';

export const SECTION_HOME = 'section-home';
export const SECTION_ACCOUNT = 'section-user-account';
export const SECTION_BRANDED_ARTICLES = 'section-branded-articles';
export const SECTION_AMAZON_CARD = 'section-amazon-card';

export const sections = [
    {
        id: SECTION_HOME,
        translationKey: 'common:section-home',
    },
    {
        id: SECTION_AMAZON_CARD,
        translationKey: 'common:amazon-gift-card-title',
    },
    // {
    //     id: SECTION_BRANDED_ARTICLES,
    //     translationKey: 'common:michelin-articles-title',
    // },
];
