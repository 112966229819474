import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Markdown from 'markdown-to-jsx';

const propTypes = {
    children: PropTypes.any,
    align: PropTypes.string,
    color: PropTypes.string,
    responsive: PropTypes.string,
};

const scales = {
    p: '1vw',
    h6: '1.5vw',
    h5: '2vw',
    h4: '2.5vw',
    h3: '3vw',
    h2: '3.5vw',
    h1: '4vw',
};

export const useStyles = makeStyles({
    responsive: {
        fontSize: ({ variant }) => scales[variant] || '6vw',
    },
    inline: {
        '& > p': {
            display: 'inline',
        },
    },
});

export const H1 = ({
    children,
    align = 'center',
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h1' });
    return (
        <Typography
            variant="h1"
            align={align}
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
H1.propTypes = propTypes;

export const H2 = ({
    children,
    align = 'center',
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h2' });
    return (
        <Typography
            variant="h2"
            align={align}
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
H2.propTypes = propTypes;

export const H3 = ({
    children,
    align = 'center',
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h3' });
    return (
        <Typography
            variant="h3"
            align={align}
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
H3.propTypes = propTypes;

export const H4 = ({
    children,
    align = 'center',
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h4' });
    return (
        <Typography
            variant="h4"
            align={align}
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
H4.propTypes = propTypes;

export const H5 = ({
    children,
    align = 'center',
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h5' });
    return (
        <Typography
            variant="h5"
            align={align}
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
H5.propTypes = propTypes;

export const H6 = ({
    children,
    align = 'center',
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h6' });
    return (
        <Typography
            variant="h6"
            align={align}
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
H6.propTypes = propTypes;

export const P = ({
    children,
    color = 'inherit',
    responsive = false,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h6' });
    return (
        <Typography
            color={color}
            className={classnames(responsive && classes.responsive)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
P.propTypes = propTypes;

const UnderlinedLinkWithSameColor = props => {
    return <Link color="inherit" underline="always" {...props} />;
};

const markdownRenderers = {
    link: UnderlinedLinkWithSameColor,
};

export const Md = ({
    children,
    inline,
    component = 'span',
    className,
    ...rest
}) => {
    const classes = useStyles({ variant: 'h6' });
    return (
        <P
            component={component}
            className={classnames(className, inline && classes.inline)}
            {...rest}
        >
            <Markdown renderers={markdownRenderers}>{children}</Markdown>
        </P>
    );
};
Md.propTypes = {
    ...propTypes,
    inline: PropTypes.string,
};
