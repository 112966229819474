import React from 'react';
import LoginForDealerSection from './sections/LoginForDealer';

const Home = () => {
    return (
        <>
            <LoginForDealerSection />
        </>
    );
};

export default Home;
