import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './components/dr/direction-reveal.scss';
import App from './App';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createGenerateClassName } from '@material-ui/styles';

import { JssProvider } from 'react-jss';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

import theme from './theme';
import * as serviceWorker from './serviceWorker';

const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
});

const client = new ApolloClient({
    uri: `${window.location.origin}/api/graphql`,
});

ReactDOM.render(
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <JssProvider generateClassName={generateClassName}>
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </JssProvider>
        </MuiThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
