import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import {
    DirectionalRevealPaper,
    GridContainer,
    H4,
    H5,
    Slider,
} from '../../components';
import OrderVoucherSection from './OrderVoucher';
import OrderHistorySection from './OrderHistory';
import { useDirectionReveal, useGlobalStyles } from '../../hooks';
import { SECTION_AMAZON_CARD } from '../';

const useStyles = makeStyles(theme => ({
    contentInDirectionalReveal: {
        padding: theme.spacing(1),
        '& *': {
            fontSize: '0.8rem',
        },
    },
}));

const carrousel = {
    urls: [
        'pages:agcod-carrousel-img-1',
        'pages:agcod-carrousel-img-2',
        'pages:agcod-carrousel-img-3',
    ],
};

const AgcodSection = ({ setVersion }) => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const { t } = useTranslation(['common', 'pages'], { useSuspense: true });
    useDirectionReveal();

    const carrouselUrls = carrousel.urls.map(t);

    return (
        <section id={SECTION_AMAZON_CARD}>
            <Slider urls={carrouselUrls} height="30%" />
            <div className={globalClasses.overlay} style={{ left: '7vw' }}>
                <H4 align="left" gutterBottom>
                    <span className="contrast">
                        {t('common:amazon-gift-card-title')}
                    </span>
                </H4>
                <H5 align="left" gutterBottom>
                    <span className="contrast">
                        {t('pages:agcod-overlay-subheader')}
                    </span>
                </H5>
                <div className="separator" />
            </div>
            <OrderVoucherSection shop="agcod" setVersion={setVersion} />
            <OrderHistorySection shop="agcod" />
            <GridContainer container className="direction-reveal" spacing={2}>
                {[1, 2, 3, 4, 5, 6].map(number => (
                    <Grid
                        key={number}
                        xs={6}
                        sm={4}
                        md={2}
                        item
                        className={globalClasses.grid_item}
                    >
                        <DirectionalRevealPaper
                            href={t(`pages:agcod-cat-${number}-href`)}
                            image={t(`pages:agcod-cat-${number}-img`)}
                            title={t(`pages:agcod-cat-${number}-title`)}
                            body={t(`pages:agcod-cat-${number}-body`)}
                            className={classes.contentInDirectionalReveal}
                        />
                    </Grid>
                ))}
            </GridContainer>
        </section>
    );
};

export default AgcodSection;
