import { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import fetchJson from 'fetch-json';
import DirectionReveal from '../components/dr/direction-reveal';
import {
    GET_AUTHENTICATED,
    GET_IDENTITY,
    ORDER_VOUCHER,
    GET_QUESTIONS,
    GET_VARIABLES,
    GET_CASINGS,
    GET_ORDERS,
} from '../queries';
import { useSnackbarContext } from '../help/context';
export { default as useGlobalStyles } from './useGlobalStyles';

export const useScrollUp = location => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
};

// Gebaseerd op https://www.robinwieruch.de/react-hooks-fetch-data/
// Maar zie ook:
// - https://medium.com/@cwlsn/how-to-fetch-data-with-react-hooks-in-a-minute-e0f9a15a44d6
// - https://stackoverflow.com/questions/53382115/custom-react-usefetch-hook-do-i-need-to-maintain-multiple-states
export const useFetch = url => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const result = await fetchJson.get(url);

            setData(result);
        } catch (error) {
            setIsError(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [url]);

    return [data, isLoading, isError];
};

export const useDirectionReveal = () => {
    useEffect(() => {
        DirectionReveal({
            selector: '.direction-reveal',
            itemSelector: '.direction-reveal__card',
            animationName: 'swing',
            animationPostfixEnter: 'enter',
            animationPostfixLeave: 'leave',
            enableTouch: true,
            touchThreshold: 250,
        });
    });
};

export const useAuthenticated = () => {
    const { loading, data } = useQuery(GET_AUTHENTICATED, {
        suspend: false,
    });

    return {
        loading,
        authenticated: loading ? null : data.authenticated,
    };
};

const useErrorNotification = error => {
    const { setAlerts } = useSnackbarContext();
    const { t } = useTranslation('exception', { useSuspense: false });

    const addAlert = useCallback(
        content => setAlerts(alerts => [content, ...alerts]),
        []
    );
    useEffect(() => {
        if (error) {
            const errorCode = `exception:${error.message.replace(
                /^GraphQL error: /i,
                ''
            )}`;
            const translatedMessage = t(errorCode);
            console.log({ error, errorCode, translatedMessage });
            addAlert(translatedMessage);
        }
    }, [error]);
};

export const useIdentity = (version, ignoreAuthError) => {
    const isFirstRun = useRef(true);
    const { data, loading, refetch, error } = useQuery(GET_IDENTITY, {
        suspend: false,
    });

    const finalError =
        error && ignoreAuthError && /authenticated/.test(error.message)
            ? undefined
            : error;
    useErrorNotification(finalError);

    useEffect(() => {
        if (version) {
            if (isFirstRun.current) {
                isFirstRun.current = false;
                return;
            }
            refetch();
        }
    }, [version]);

    return {
        loading,
        identity: loading ? null : data && data.identity,
    };
};

export const useOrderVoucher = ({ onCompleted }) => {
    const [mutate, { loading, error }] = useMutation(ORDER_VOUCHER, {
        suspend: false,
        onCompleted,
    });
    useErrorNotification(error);

    return [mutate, { loading }];
};

export const useQuestions = language => {
    const { data, loading, error } = useQuery(GET_QUESTIONS, {
        suspend: false,
        variables: {
            language,
        },
    });
    useErrorNotification(error);

    return {
        loading,
        error,
        data: loading ? null : data && data.questions,
    };
};

export const useCasings = () => {
    const { data, loading, error } = useQuery(GET_CASINGS, {
        suspend: false,
    });
    useErrorNotification(error);

    return {
        loading,
        error,
        data: loading ? null : data && data.casings,
    };
};

export const useOrders = () => {
    const { data, loading, error } = useQuery(GET_ORDERS, {
        suspend: false,
    });
    useErrorNotification(error);

    return {
        loading,
        error,
        data: loading ? null : data && data.orders,
    };
};

export const useVariables = () => {
    const { loading, data } = useQuery(GET_VARIABLES, {
        suspend: false,
    });

    return {
        loading,
        ...(loading ? {} : data.variables),
    };
};
