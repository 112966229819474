import React from 'react';
import LoginDecisionSection from './sections/LoginDecision';

const Home = () => {
    return (
        <>
            <LoginDecisionSection />
        </>
    );
};

export default Home;
