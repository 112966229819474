import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';
import InformationVariantIcon from 'mdi-material-ui/InformationVariant';
import theme from '../theme';
import { useHelpContext } from './context';

const color = theme.palette.type === 'dark' ? 'primary' : 'secondary';

const OpenTopicButton = ({ topic, className }) => {
    // 3️⃣ Use context instead of custom hook
    const { t } = useTranslation('help', { useSuspense: false });
    const { setTopic } = useHelpContext();

    const tooltipText = `${t('help:learn-more-about')} ${t(
        `help:topic-${topic}-title`
    )}`;

    return (
        <Tooltip title={tooltipText} arrow>
            <Button
                size="small"
                color={color}
                className={className}
                onClick={() => setTopic(topic)}
            >
                <InformationVariantIcon />
            </Button>
        </Tooltip>
    );
};
OpenTopicButton.propTypes = {
    className: PropTypes.string,
    topic: PropTypes.string,
};

export default OpenTopicButton;
