import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { utils as XLSX_utils, writeFile as XLSX_writeFile } from 'xlsx';
import { H6 } from '../components';
import { useCasings } from '../hooks';
import { dateFormat } from '../utils/date';

const useStyles = makeStyles({});

const lookupReducer = (acc, b) => {
    acc[b] = b;
    return acc;
};
const getLookupList = (casingsData, fieldName) =>
    [...new Set(casingsData.map(item => item[fieldName]))]
        .sort()
        .reverse()
        .reduce(lookupReducer, {});

// https://github.com/mbrn/material-table/issues/1534
const exportToExcel = (columns, data) => {
    // determining header labels
    const columnInfo = columns.reduce(
        (acc, column) => {
            const headerLabel = column.title || column.field;
            acc.header.push(headerLabel);
            acc.map[column.field] = column;
            return acc;
        },
        { map: {}, header: [] }
    );

    const rowTransformer = (acc, [key, value]) => {
        if (columnInfo.map[key]) {
            const column = columnInfo.map[key];
            const headerLabel = column.title || column.field;
            acc[headerLabel] = column.lookup ? column.lookup[value] : value;
        }
        return acc;
    };

    // Translate the keys of each row object to the new header label
    const mappedData = data.map(row =>
        Object.entries(row).reduce(rowTransformer, {})
    );

    // https://github.com/SheetJS/sheetjs/issues/817
    var ws = XLSX_utils.json_to_sheet(mappedData, {
        header: columnInfo.header, // garanties the same column order
    });

    /* add to workbook */
    var wb = XLSX_utils.book_new();
    XLSX_utils.book_append_sheet(wb, ws, 'Sheet1');

    /* generate an XLSX file */
    XLSX_writeFile(
        wb,
        `casings-report-${dateFormat(new Date(), 'yyyy-MM-dd')}.xlsx`
    );
};

const statusLists = {
    accepted: ['A1'],
    rejected: ['D1', 'D2', 'D4', 'D5', 'R1', 'R2', 'S1', '01', '03', '07'],
    inprocess: ['T1', 'T3', 'T7', 'T8', 'T9', 'V2', '02', '04', '05', '06'],
};
const termTests = {
    accepted: /go|ge|ac/i, // matcht: goed, geod, accepte
    rejected: /af|re/i, // matcht: afgekeurd, rejected, refusee
    inprocess: /in|en|be|co/i, // matcht: in bewerking en cours
};

const CasingsReport = ({ refRable }) => {
    const classes = useStyles();
    const { t } = useTranslation('casingsreport', { useSuspense: false });

    const casings = useCasings('nl');
    const casingsData = casings.loading ? [] : casings.data;

    const columns = useMemo(
        () => [
            {
                field: 'year',
                title: t('casingsreport:year'),
                lookup: getLookupList(casingsData, 'year'),
            },
            {
                field: 'month',
                title: t('casingsreport:month'),
                lookup: getLookupList(casingsData, 'month'),
            },
            {
                field: 'docket_number',
                title: t('casingsreport:docket_number'),
            },
            refRable === 'dealer'
                ? { field: 'cu_name', title: t('casingsreport:cu_name') }
                : {
                      field: 'ns_name',
                      title: t('casingsreport:ns_name'),
                      lookup: getLookupList(casingsData, 'ns_name'),
                  },
            { field: 'product_in', title: t('casingsreport:product_in') },
            { field: 'product_out', title: t('casingsreport:product_out') },
            { field: 'matricule', title: t('casingsreport:matricule') },
            {
                field: 'current_status',
                title: t('casingsreport:current_status'),
                customFilterAndSearch: (term, rowData) =>
                    (termTests.accepted.test(term) &&
                        statusLists.accepted.includes(
                            rowData.current_status
                        )) ||
                    (termTests.rejected.test(term) &&
                        statusLists.rejected.includes(
                            rowData.current_status
                        )) ||
                    (termTests.inprocess.test(term) &&
                        statusLists.inprocess.includes(rowData.current_status)),

                render: rowData =>
                    rowData.current_status
                        ? t(
                              `casingsreport:status_${rowData.current_status.toLowerCase()}`,
                              rowData.current_status
                          )
                        : '?',
            },
        ],
        [t, refRable, casingsData]
    );

    console.log(casings);

    return (
        <div style={{ maxWidth: '100%' }}>
            <H6>{t(`casingsreport:title`)}</H6>
            <MaterialTable
                columns={columns}
                data={casingsData}
                options={{
                    showTitle: false,
                    search: false,
                    // toolbar: false,
                    filtering: true,
                    exportButton: true,
                    exportCsv: exportToExcel,
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: t(
                            'casingsreport:emptyDataSourceMessage'
                        ),
                    },
                    toolbar: {
                        searchPlaceholder: t('casingsreport:searchPlaceholder'),
                        exportTitle: 'Download',
                        exportAriaLabel: 'Download',
                        exportName: 'Download als Excel',
                    },
                }}
            />
        </div>
    );
};
CasingsReport.propTypes = {
    refRable: PropTypes.string,
};

export default CasingsReport;
