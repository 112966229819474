import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DecisionRoute, PrivateRoute } from '../components';
import { HomeAnonymous, HomeAuthenticated, HomeDealerLogin } from './';
import { useAuthenticated } from '../hooks';

const redirectToProfile = () => <Redirect to="/me" />;

const Routes = ({ setVersion }) => {
    const { loading, authenticated } = useAuthenticated();
    const { i18n } = useTranslation();

    if (loading) return null;

    const isAuthenticated = () => authenticated;

    const detectedLanguage = i18n.language;
    const detectedLanguageShort =
        detectedLanguage && detectedLanguage.slice(0, 2);

    // Switch returns only the first matching route
    return (
        <Switch>
            <Route
                exact
                path="/me"
                render={() => <Redirect to={`/${detectedLanguageShort}/me`} />}
            />
            <Route
                exact
                path="/dealer"
                render={() => (
                    <Redirect to={`/${detectedLanguageShort}/dealer`} />
                )}
            />
            <PrivateRoute
                exact
                path="/:lng/me"
                component={HomeAuthenticated}
                authenticated={authenticated}
                componentProps={{ setVersion }}
            />
            <DecisionRoute
                exact
                path="/:lng/dealer"
                decisionFunc={isAuthenticated}
                trueComponent={redirectToProfile}
                falseComponent={HomeDealerLogin}
            />
            <DecisionRoute
                exact
                path="/:lng/"
                decisionFunc={isAuthenticated}
                trueComponent={redirectToProfile}
                falseComponent={HomeAnonymous}
            />
            <Route
                path="*"
                render={() => <Redirect to={`/${detectedLanguageShort}`} />}
            />
        </Switch>
    );
};
Routes.propTypes = {
    setVersion: PropTypes.func,
};

export default Routes;
