import {
    parseISO,
    format,
    formatDistance,
    differenceInCalendarDays,
} from 'date-fns';
import { nl } from 'date-fns/locale';

export const ensureDate = dateString =>
    typeof dateString === 'string' ? parseISO(dateString) : dateString;

export const dateFormat = (dateString, pattern) =>
    format(ensureDate(dateString), pattern, { locale: nl });

/**
 * Minder dan een minuut, een minuut, ongeveer een uur, etc
 */
export const fromNow = dateString => {
    return formatDistance(ensureDate(dateString), new Date(), { locale: nl });
};

export const daysDiff = (theLaterDate, theEarlierDate) =>
    differenceInCalendarDays(
        ensureDate(theLaterDate),
        ensureDate(theEarlierDate)
    );

export const daysAgo = date => daysDiff(new Date(), date);

export const daysInFuture = date => daysDiff(date, new Date());
