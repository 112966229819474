import React, { useContext } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from 'mdi-material-ui/Close';
import { P, Responsive } from './';
import { Context } from '../context';
import theme from '../theme';

const useStyles = makeStyles({
    // alternatief met keyframes, maar dan zie je altijd een zakkende cookiebar
    // aan het begin
    // '@keyframes slideDown': {
    //     from: { transform: 'translateY(0%)' },
    //     to: { transform: 'translateY(120%)' }
    // },
    cookiebar: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: 40,
        transition: 'transform 1s ease',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        boxShadow: '0px -5px 5px 0px rgba(0,0,0,0.5)',
        zIndex: '9999',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        '&.closed-normally': {
            transform: 'translateY(120%)',
            // animation: '$slideDown 1s forwards',
        },
        '&.closed-rockandroll': {
            transform: 'rotate(180deg) scale(0)',
            transformOrigin: 'bottom',
            // animation: '$slideDown 1s forwards',
        },
        '& .custom, .white': {
            color: theme.palette.secondary.contrastText,
        },
        '& p': {
            textAlign: 'center',
            fontSize: '.8em',
        },
    },
});

const CookieInfo = () => {
    const classes = useStyles();
    const { dispatch, closedCookieInfo } = useContext(Context);

    const handleClick = how => () => {
        dispatch({
            type: 'SET_STATE',
            closedCookieInfo: how,
        });
    };

    return (
        <div className={classnames(classes.cookiebar, closedCookieInfo)}>
            <P className="white">
                Deze site gebruikt cookies. Klik{' '}
                <a
                    className="custom"
                    href="https://www.michelin.com/eng/Privacy-Policy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    hier
                </a>{' '}
                voor meer informatie.{' '}
            </P>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClick('closed-normally')}
                >
                    <Responsive
                        xsmall={<CloseIcon />}
                        medium={<span>Sluiten</span>}
                    />
                </Button>
                <Button
                    color="primary"
                    size="small"
                    onClick={handleClick('closed-rockandroll')}
                >
                    <span role="img" aria-label="Rock & Roll">
                        🤘
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default CookieInfo;
