import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Scrollspy from 'react-scrollspy';
import { useWindowSize } from 'react-use';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Button, Fab, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import MenuIcon from 'mdi-material-ui/Menu';
import MenuRightIcon from 'mdi-material-ui/MenuRight';
import ChevronTripleUpIcon from 'mdi-material-ui/ChevronTripleUp';
import HelpCircleOutlineIcon from 'mdi-material-ui/HelpCircleOutline';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import theme from '../theme';
import { useHelpContext } from '../help';

import { sections } from '../pages';

const useStyles = makeStyles({
    menu_paper: {
        '& a.current': {
            fontWeight: 'bold',
        },
    },
    scrollToTopLink: {
        transition: 'opacity 1s linear',
        position: 'fixed',
        opacity: 0.6,
        right: theme.spacing(4),
        bottom: theme.spacing(4),
        zIndex: 9999,
        '&.reduced': {
            opacity: 0,
        },
    },
    emptyListStyle: {
        margin: 0,
        padding: 0,
        position: 'relative',
        listStyle: 'none',
    },
});

const MainMenu = ({ atTop }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { width } = useWindowSize();
    const { t } = useTranslation(['common', 'pages'], { useSuspense: true });
    const { setTopic } = useHelpContext();
    const handleFaqClick = useCallback(() => {
        setTopic('faq');
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback(
        event => setAnchorEl(event.currentTarget),
        []
    );
    const handleClose = useCallback(() => setAnchorEl(null), []);

    const isSmall = width < theme.breakpoints.values.sm;

    return (
        <div>
            <Fab
                href={`#${sections[0].id}`}
                offset={
                    isSmall ? theme.HEADER_HEIGHT_MOBILE : theme.HEADER_HEIGHT
                }
                className={classnames(classes.scrollToTopLink, {
                    reduced: atTop,
                })}
                component={AnchorLink}
                variant="round"
            >
                <ChevronTripleUpIcon />
            </Fab>
            <Button
                aria-controls="main-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon />
            </Button>
            <Menu
                id="main-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menu_paper }}
            >
                <Scrollspy
                    items={sections.map(section => section.id)}
                    className={classes.emptyListStyle}
                    currentClassName="current"
                >
                    {sections.map((section, index) => (
                        <MenuItem
                            key={index}
                            component={AnchorLink}
                            href={`#${section.id}`}
                            onClick={handleClose}
                            offset={
                                index > 0 || isSmall
                                    ? theme.HEADER_HEIGHT_MOBILE
                                    : theme.HEADER_HEIGHT
                            }
                        >
                            <ListItemIcon>
                                <MenuRightIcon fontSize="small" />
                            </ListItemIcon>
                            {t(section.translationKey)}
                        </MenuItem>
                    ))}
                    <MenuItem onClick={handleFaqClick}>
                        <ListItemIcon>
                            <HelpCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        {t('common:faq-title')}
                    </MenuItem>
                </Scrollspy>
            </Menu>
        </div>
    );
};
MainMenu.propTypes = {
    atTop: PropTypes.bool,
};

export default MainMenu;
