import React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from 'mdi-material-ui/ContentCopy';
import copy from 'copy-to-clipboard';

const styles = {
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: 8,
        // flex: 1,
        fontFamily: 'Courier',
    },
    iconButton: {
        padding: 10,
    },
};

const GcClaimCode = ({ gcClaimCode }) => {
    return (
        <div style={styles.root}>
            <InputBase
                style={styles.input}
                value={gcClaimCode}
                readOnly={true}
            />
            <IconButton
                style={styles.iconButton}
                aria-label="Copy"
                onClick={() => copy(gcClaimCode)}
            >
                <ContentCopyIcon />
            </IconButton>
        </div>
    );
};
GcClaimCode.propTypes = {
    gcClaimCode: PropTypes.string,
};

export default GcClaimCode;
