import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import { H6, P } from './';

const useStyles = makeStyles({
    paper: {
        alignSelf: 'stretch',
        height: '100%',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%',
        },
        // onderstaande zorg ervoor dat swing netjes vanuit de hoeken begint
        '& .direction-reveal__overlay': {
            right: 0,
            bottom: 0,
            width: 'unset',
            height: 'unset',
        },
    },
});

const DirectionalRevealPaper = ({ href, image, title, body, className }) => {
    const classes = useStyles();

    return (
        <Paper
            component="a"
            href={href}
            target="_blank"
            className={classnames('direction-reveal__card', classes.paper)}
        >
            <img src={image} className="img-fluid" />
            <div
                className={classnames(
                    'direction-reveal__overlay',
                    'direction-reveal__anim--enter',
                    className
                )}
            >
                <H6>{title}</H6>
                <P>{body}</P>
            </div>
        </Paper>
    );
};
DirectionalRevealPaper.propTypes = {
    href: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    className: PropTypes.string,
};

export default DirectionalRevealPaper;
