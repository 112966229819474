import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import lodashDebounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import MagnifyIcon from 'mdi-material-ui/Magnify';
import { GridContainer, H5, Md } from '../components';
import { useQuestions, useGlobalStyles } from '../hooks';

const useStyles = makeStyles({
    container: {
        // border: '1px dashed green',
    },
    item: {
        // border: '1px dashed red',
    },
});

const replaceWithBold = (
    wholeMatch,
    optionalStuffBefore = '',
    value,
    optionalStuffAfter = ''
) => `${optionalStuffBefore}**${value}**${optionalStuffAfter}`;
const replaceWithLink = (wholeMatch, linkWord) => {
    const replacement = `<a href="https://www.myportal.michelingroup.com/portal/s/contact-us" target="_blank">${linkWord}</a>`;
    return replacement;
};
const transformForPresentation = (content, keyword) => {
    content = content.replace(
        /(contactformulier|formulaire de contact)/gi,
        replaceWithLink
    );

    if (keyword) {
        content = content.replace(keyword, replaceWithBold);
    }

    return content;
};

const FaqContent = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const [keyword, setKeyword] = useState();
    // onderstaande omdat de HelpDrawer buiten routing valt en ik de match params niet kan ophalen
    const languageRef = useRef(
        (`${window.location.href}`.match(/\/(nl|fr|de|en)/) || ['', 'nl'])[1]
    );
    const questions = useQuestions(languageRef.current);
    const { t } = useTranslation(['help'], {
        useSuspense: false,
    });

    const debouncedSetKeyword = lodashDebounce(value => {
        setKeyword(
            value
                ? new RegExp(
                      // https://stackoverflow.com/questions/958095/use-regex-to-find-specific-string-not-in-html-tag
                      `(<.+?>[^<>]*?)?(${value})([^<>]*?<.+?>)?`,
                      'gi'
                  )
                : null
        );
    }, 500);

    const handleChange = useCallback(
        event => debouncedSetKeyword(event.target.value),
        []
    );

    if (questions.loading || questions.error) return <span>...</span>;

    const categories = questions.data.reduce((acc, row) => {
        if (
            !keyword ||
            keyword.test(row.question) ||
            keyword.test(row.answer)
        ) {
            const key = row.category;
            acc[key] = acc[key] || [];
            acc[key].push(row);
        }
        return acc;
    }, {});

    const rowCallback = (row, index) => (
        <Grid key={index} xs={12} sm={12} md={12} item className={classes.item}>
            <Card className={globalClasses.stretchedCard}>
                <CardContent>
                    <Md color="textSecondary">
                        {transformForPresentation(row.question, keyword)}
                    </Md>
                    <br />
                    <Md>{transformForPresentation(row.answer, keyword)}</Md>
                </CardContent>
            </Card>
        </Grid>
    );

    return (
        <>
            <TextField
                label={t('help:faq-search')}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <MagnifyIcon />
                        </InputAdornment>
                    ),
                }}
                variant="filled"
                color="secondary"
                onChange={handleChange}
            />
            {Object.entries(categories).map(([category, rows]) => (
                <div key={category}>
                    <H5 align="center">{category}</H5>
                    <GridContainer spacing={2} className={classes.container}>
                        {rows.map(rowCallback)}
                    </GridContainer>
                </div>
            ))}
        </>
    );
};

export default FaqContent;
