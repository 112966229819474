import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

const reducer = (state, action, provider) => {
    const { type, ...rest } = action;
    if (type === 'SET_STATE') {
        return {
            ...state,
            ...rest,
        };
    }
    if (type === 'INITIAL_STATE') {
        return {
            dispatch: state.dispatch,
            getState: state.getState,
            ...provider.__initialState,
            ...rest, // optionally add something to that initial state right away
        };
    }
    if (type === 'PERSISTED_STATE') {
        return {
            dispatch: state.dispatch,
            getState: state.getState,
            ...provider.__persistedState,
            ...rest, // optionally add something to that persisted state right away
        };
    }
};

const stringifyableObject = object => {
    // Eentje die ook nested objecten zou aankunnen is:
    // https://stackoverflow.com/a/48845206/1385429
    const simpleObject = {};
    let type, prop;
    for (prop in object) {
        if (!Object.prototype.hasOwnProperty.call(object, prop)) {
            continue;
        }
        type = typeof object[prop];
        if (type === 'object') {
            continue;
        }
        if (type === 'function') {
            continue;
        }
        simpleObject[prop] = object[prop];
    }
    return simpleObject; // returns stringifyable object
};

const simpleStringify = object => JSON.stringify(stringifyableObject(object));

// export to import using react-context-consumer-hoc
export const Context = createContext();

export class Provider extends Component {
    static propTypes = {
        children: PropTypes.any,
        __config: PropTypes.object,
    };
    constructor(props) {
        super(props);
        const { __config = {}, ...rest } = props;
        if (__config.persist) {
            this.__lsKey = __config.persistKey
                ? `context-${__config.persistKey}`
                : 'context';
            this.__persist = __config.persist;
            this.__persistGetter = __config.persist.getItem ? 'getItem' : 'get';
            this.__persistSetter = __config.persist.setItem ? 'setItem' : 'set';
            try {
                this.__persistedState = JSON.parse(
                    this.__persist[this.__persistGetter](this.__lsKey)
                );
            } catch (e) {
                //
            }
        }
        this.__initialState = rest;

        this.state = {
            dispatch: action => {
                this.setState(state => reducer(state, action, this));
            },
            getState: () => stringifyableObject(this.state),
            // Add initial provider props to initial state
            ...this.__initialState,
            // Add localStorage persistedState to initial state
            ...this.__persistedState,
        };
    }

    componentDidUpdate() {
        if (!this.__lsKey) return;
        const stateString = simpleStringify(this.state);
        this.__persist[this.__persistSetter](this.__lsKey, stateString);
    }

    render() {
        const {
            state,
            props: { children },
        } = this;
        return <Context.Provider value={state}>{children}</Context.Provider>;
    }
}

const makeProviderProps = (mapOwnPropsToProviderProps, ownProps) => {
    if (typeof mapOwnPropsToProviderProps === 'function') {
        return mapOwnPropsToProviderProps(ownProps);
    }
    return mapOwnPropsToProviderProps || {};
};

// This function takes a function and returns a HOC.
export const withProvider = (
    mapOwnPropsToProviderProps,
    config
) => WrappedComponent => {
    // ...that returns another component...
    return function ComponentWithProvider(props) {
        // ... and renders the wrapped component with the Provider!
        // Notice that we pass through any additional props as well
        const providerProps = makeProviderProps(
            mapOwnPropsToProviderProps,
            props
        );

        return (
            <Provider {...providerProps} __config={config}>
                <WrappedComponent {...props} />
            </Provider>
        );
    };
};

/**
 * Pattern inspired after google search:
 * react context handle function passed through context
 * found: https://dev.to/washingtonsteven/reacts-new-context-api-and-actions-446o
 * alternatieven en inspiratie:
 * - https://medium.freecodecamp.org/replacing-redux-with-the-new-react-context-api-8f5d01a00e8c
 * - https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
 */
