import React from 'react';
import PropTypes from 'prop-types';
import {
    RadialBar,
    RadialBarChart,
    ResponsiveContainer,
    PolarAngleAxis,
    Legend,
} from 'recharts';
import CountUp from 'react-countup';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
// import AutorenewIcon from 'mdi-material-ui/Autorenew';
import { InView, P } from './';
import theme from '../theme';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        // breedte per blok = 30% ongeveer, want 3 stuks in de breedte
        // dat vertaald zich naar 30vw ongeveer (vw = A percentage of the full viewport width)
        // Aangezien de dials nu geen rondjes meer zijn maar hale rondjes,
        // moet de hoogte dus 15vw zijn
        height: '15vw',
    },
    overlay: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .counter': {
            fontSize: '3vw',
        },
        '& .miniTopIcon': {
            position: 'absolute',
            color: theme.palette.background.default,
            top: 0,
            height: '23%',
            transform: 'scale(0.8)',
            [theme.breakpoints.down('sm')]: {
                height: '22%',
                transform: 'scale(1.2)',
            },
            paddingLeft: 20,
        },
    },
});

const radialBarChartMargin = { top: 0, right: 0, bottom: 0, left: 0 };

const EyeCatchingProgressChart = ({ unit, data, domainMax, legend, label }) => {
    const classes = useStyles();

    const centerValue = data[0].value;

    const innerRadius = `${100 - 20 * data.length}%`;

    const dialBackgroundColor = theme.palette.background.default;

    const finalDomainMax = Math.max(...data.map(item => item.value), domainMax);

    return (
        <InView className={classnames(classes.root)}>
            <ResponsiveContainer
                width="100%"
                height="200%"
                className={classnames(classes.root2)}
            >
                <RadialBarChart
                    innerRadius={innerRadius}
                    outerRadius="100%"
                    data={data}
                    startAngle={180}
                    endAngle={0}
                    margin={radialBarChartMargin}
                >
                    <PolarAngleAxis
                        type="number"
                        domain={[0, finalDomainMax]}
                        dataKey={'uv'}
                        tick={false}
                    />
                    <RadialBar
                        background={{ fill: dialBackgroundColor }}
                        label={{
                            position: 'insideStart',
                            fill: data[0].textFill,
                            formatter: value =>
                                label ? `${value} ${unit}` : '',
                        }}
                        cornerRadius={10}
                        dataKey="value"
                    />
                    {legend ? (
                        <Legend
                            iconSize={10}
                            width={120}
                            height={140}
                            layout="horizontal"
                            verticalAlign="middle"
                            wrapperStyle={{
                                fontSize: '80%',
                                margin: '0 auto',
                                right: 0,
                                left: 0,
                            }}
                        />
                    ) : null}
                </RadialBarChart>
            </ResponsiveContainer>
            <div className={classes.overlay}>
                <CountUp end={centerValue} className="counter" separator="." />
                <P color="textSecondary">{unit}</P>
                {/* <AutorenewIcon className="miniTopIcon" /> */}
            </div>
        </InView>
    );
};
EyeCatchingProgressChart.propTypes = {
    unit: PropTypes.string,
    data: PropTypes.array,
    domainMax: PropTypes.number,
    legend: PropTypes.bool,
    label: PropTypes.bool,
};

export default EyeCatchingProgressChart;
