import React, { createElement, useState, useCallback } from 'react';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Button,
    Tooltip,
} from '@material-ui/core';
import ChevronDownIcon from 'mdi-material-ui/ChevronDown';
import ChevronUpIcon from 'mdi-material-ui/ChevronUp';
import TableSearchIcon from 'mdi-material-ui/TableSearch';
import {
    CasingsReport,
    EyeCatchingProgressChart,
    GridContainer,
    H6,
} from '../../components';
import { useIdentity, useGlobalStyles } from '../../hooks';
import theme from '../../theme';
import { SECTION_HOME } from '../';
import { useHelpContext } from '../../help';

const useStyles = makeStyles({
    CardContentHeader_root: {
        padding: `${theme.spacing(1)}px 0px 0px 0px`,
    },
    CardContentChart_root: {
        padding: `0px 0px ${theme.spacing(2)}px 0px`,
    },
    header: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '& button': {
            padding: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
            marginLeft: `${theme.spacing(1)}px`,
        },
    },
    grayscale: {
        filter: `grayscale(100%) brightness(${
            theme.palette.type === 'dark' ? '30' : '100'
        }%)`,
    },
    [theme.breakpoints.down('xs')]: {
        GridContainer: {
            '& > :nth-child(2)': {
                order: 1,
            },
            '& > :not(:nth-child(2))': {
                order: 2,
            },
        },
    },
});

const dataWateSaved = [
    {
        name: 'points',
        value: 40,
        fill: '#75ed01',
        identityKey: 'waste_saved_in_kg',
    },
];

const dataCasings = [
    {
        name: 'mystats-casingsthisyear-legend',
        value: 0,
        fill: theme.palette.primary.main,
        identityKey: 'accepted_casings_this_year',
    },
    {
        name: 'mystats-casingslastyear-legend',
        value: 0,
        fill: theme.palette.secondary.main,
        identityKey: 'accepted_casings_last_year',
    },
];

const dataAcceptenceRate = [
    {
        name: '%',
        value: 0,
        fill: '#75ed01',
        identityKey: 'acceptance_rate',
    },
];

const UserAccountSection = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const { width } = useWindowSize();
    const { setTopic } = useHelpContext();
    const { t, ready } = useTranslation(['common', 'help'], {
        useSuspense: false,
    });
    const { identity, loading } = useIdentity();
    const [showingCasingsReport, setShowingCasingsReport] = useState(false);

    const handleClickToggleCasings = event => {
        event.stopPropagation();
        setShowingCasingsReport(!showingCasingsReport);
    };

    const isSmall = width < theme.breakpoints.values.sm;
    const offset = theme.HEADER_HEIGHT_MOBILE;

    const mapFunction = item => ({
        ...item,
        value: loading || !identity ? 0 : identity[item.identityKey] || 0,
        name: t(item.name),
    });

    const dataWateSavedForRender = dataWateSaved.map(mapFunction);
    const dataCasingsForRender = dataCasings.map(mapFunction);
    const dataAcceptenceRateForRender = dataAcceptenceRate.map(mapFunction);

    const headerComponent = isSmall ? H6 : H6;

    console.log({ identity });

    return (
        <section id={SECTION_HOME}>
            <GridContainer spacing={2} className={classes.GridContainer}>
                <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    item
                    className={globalClasses.grid_item}
                >
                    <Card className={globalClasses.stretchedCard}>
                        <CardContent className={classes.CardContentHeader_root}>
                            {createElement(
                                headerComponent,
                                {
                                    gutterBottom: true,
                                    align: 'center',
                                    className: classes.header,
                                },
                                t('common:mystats-1st-title')
                            )}
                        </CardContent>
                        <CardActionArea onClick={() => setTopic('mystats-1st')}>
                            <CardContent
                                className={classes.CardContentChart_root}
                            >
                                <EyeCatchingProgressChart
                                    unit="kg"
                                    domainMax={5000}
                                    data={dataWateSavedForRender}
                                />
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    item
                    className={globalClasses.grid_item}
                >
                    <Card className={globalClasses.stretchedCard}>
                        <CardContent className={classes.CardContentHeader_root}>
                            {createElement(
                                headerComponent,
                                {
                                    gutterBottom: true,
                                    align: 'center',
                                    className: classes.header,
                                },
                                <span>
                                    {t('common:mystats-casingsthisyear-title')}
                                    <Tooltip
                                        title={t(
                                            showingCasingsReport
                                                ? 'common:close-casingsreport'
                                                : 'common:open-casingsreport'
                                        )}
                                        arrow
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={handleClickToggleCasings}
                                            startIcon={<TableSearchIcon />}
                                            endIcon={
                                                showingCasingsReport ? (
                                                    <ChevronUpIcon />
                                                ) : (
                                                    <ChevronDownIcon />
                                                )
                                            }
                                        />
                                    </Tooltip>
                                </span>
                            )}
                        </CardContent>
                        <CardActionArea
                            onClick={() => setTopic('casingsthisyear')}
                        >
                            <CardContent
                                className={classes.CardContentChart_root}
                            >
                                <EyeCatchingProgressChart
                                    unit=""
                                    domainMax={100}
                                    data={dataCasingsForRender}
                                />
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    item
                    className={globalClasses.grid_item}
                >
                    <Card className={globalClasses.stretchedCard}>
                        <CardContent className={classes.CardContentHeader_root}>
                            {createElement(
                                headerComponent,
                                {
                                    gutterBottom: true,
                                    align: 'center',
                                    className: classes.header,
                                },
                                t('common:mystats-3rd-title')
                            )}
                        </CardContent>
                        <CardActionArea onClick={() => setTopic('mystats-3rd')}>
                            <CardContent
                                className={classes.CardContentChart_root}
                            >
                                <EyeCatchingProgressChart
                                    unit="%"
                                    domainMax={100}
                                    data={dataAcceptenceRateForRender}
                                />
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {showingCasingsReport && (
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        item
                        className={globalClasses.grid_item}
                    >
                        <CasingsReport
                            refRable={identity && identity.ref_table}
                        />
                    </Grid>
                )}
                {/*Hier starten de 2 blokken die linken naar BA en Amazon */}
                {/* <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    item
                    className={globalClasses.grid_item}
                >
                    <Card className={globalClasses.stretchedCard}>
                        <CardActionArea
                            component={AnchorLink}
                            href={`#${SECTION_BRANDED_ARTICLES}`}
                            offset={offset}
                        >
                            <CardMedia
                                component="img"
                                alt={t('common:michelin-articles-title')}
                                height="140"
                                image={
                                    ready
                                        ? t('common:michelin-articles-img')
                                        : ''
                                }
                                title={t('common:michelin-articles-title')}
                            />
                            <CardContent className="pushButtonsDown">
                                <H6 gutterBottom>
                                    {t('common:michelin-articles-title')}
                                </H6>
                                <Md variant="body2" color="textSecondary">
                                    {t('common:michelin-articles-body')}
                                </Md>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <OpenTopicButton topic="michelinarticles" />
                        </CardActions>
                    </Card>
                </Grid>
                <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    item
                    className={globalClasses.grid_item}
                >
                    <Card className={globalClasses.stretchedCard}>
                        <CardActionArea
                            component={AnchorLink}
                            href={`#${SECTION_AMAZON_CARD}`}
                            offset={offset}
                        >
                            <CardMedia
                                component="img"
                                alt={t('common:amazon-gift-card-title')}
                                height="140"
                                image={
                                    ready
                                        ? t('common:amazon-gift-card-img')
                                        : ''
                                }
                                title={t('common:amazon-gift-card-title')}
                            />
                            <CardContent className="pushButtonsDown">
                                <H6 gutterBottom>
                                    {t('common:amazon-gift-card-title')}
                                </H6>
                                <Md variant="body2" color="textSecondary">
                                    {t('common:amazon-gift-card-body')}
                                </Md>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <OpenTopicButton topic="amazoncard" />
                        </CardActions>
                    </Card>
                </Grid> */}
            </GridContainer>
        </section>
    );
};

export default UserAccountSection;
