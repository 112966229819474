import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { P, GridContainer } from './';
import theme from '../theme';

const useStyles = makeStyles({
    footer: {
        backgroundColor: theme.palette.background.default,
        borderTop: `${theme.HEADER_SEPARATOR_HEIGHT}px solid ${theme.palette.primary.main}`,
    },
    sitemap: {
        color: theme.palette.text.secondary,
    },
    nav: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: '.875em',
        '& ul': {
            padding: 0,
            '& li': {
                display: 'inline-block',
                padding: '0 30px',
                '& a': {
                    display: 'block',
                    color: 'inherit',
                },
                '& a:before': {
                    content: '">"',
                    width: 10,
                    height: 10,
                    display: 'inline-block',
                    marginRight: 6,
                    verticalAlign: -1,
                    fontSize: 16,
                },
            },
        },
    },
    copyright: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        fontSize: '.875em',
    },
});

const SHOW_WHITE_CONTENT_AREA = false;

const Footer = () => {
    const classes = useStyles();
    const x = useTranslation('common', { useSuspense: false });

    const { t, i18n } = x;

    return (
        <footer className={classes.footer}>
            {SHOW_WHITE_CONTENT_AREA && (
                <GridContainer
                    container
                    className={classes.sitemap}
                    spacing={4}
                >
                    <Grid xs={12} md={6} item>
                        <P align="center">{t('common:footer-left-column')}</P>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <P align="center">{t('common:footer-right-column')}</P>
                    </Grid>
                </GridContainer>
            )}
            <div className={classes.nav}>
                <ul>
                    {[1, 2, 3, 4, 5].flatMap(number =>
                        i18n.exists(`common:footer-link${number}-href`) ? (
                            <li key={number}>
                                <a href={t(`common:footer-link${number}-href`)}>
                                    {t(`common:footer-link${number}-text`)}
                                </a>
                            </li>
                        ) : (
                            undefined
                        )
                    )}
                </ul>
            </div>

            <div className={classes.copyright}>
                <P color="textSecondary">{t('common:footer-copyright')}</P>
            </div>
        </footer>
    );
};

export default Footer;
