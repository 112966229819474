import React from 'react';
import PropTypes from 'prop-types';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwsSliderStyles from 'react-awesome-slider/dist/styles.css';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import theme from '../theme';

const useStyles = makeStyles({
    container: {
        // bolletjes in en bovenop de image plaatsen ipv eronder
        '& nav': {
            bottom: 10,
            zIndex: 100,
        },
        // Zorgen dat bij <500px niet ineens de slider hoger wordt en daardoor
        // de voeten van de Michelin niet meer uitsteken.
        // https://github.com/rcaferati/react-awesome-slider/blob/859a8733ac5fe9ba44f23ae749a048b82ab87bf0/src/core/styles.scss#L87-L89
        '& .aws-sld__container': {
            paddingBottom: 'var(--slider-height-percentage)',
        },
    },
    caption: {
        zIndex: 100,
        // @todo dit moet een divje hoger, zodat caption zichtbaar wordt...
    },
});

const { PUBLIC_URL } = process.env;
const httpRe = /^http/;
const toRealUrl = url => `${httpRe.test(url) ? '' : PUBLIC_URL}${url}`;

const AutoplaySlider = withAutoplay(AwesomeSlider);

// --slider-height-percentage: 56%;

const Slider = ({ urls, captions = [], height = '100%' }) => {
    const classes = useStyles();

    return (
        <AutoplaySlider
            cssModule={AwsSliderStyles}
            className={classes.container}
            style={{
                '--slider-height-percentage': height,
                '--loader-bar-color': theme.palette.secondary.main,
                '--loader-bar-height': '4px',
                '--organic-arrow-height': '20px',
                '--organic-arrow-color': '#000000',
                '--control-bullet-color': fade(
                    theme.palette.secondary.dark,
                    0.7
                ),
                '--control-bullet-active-color': fade(
                    theme.palette.primary.dark,
                    0.7
                ),
            }}
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={6000}
        >
            {urls.map(toRealUrl).map((url, index) => (
                <div key={index} data-src={url}>
                    <p className={classes.caption}>{captions[index]}</p>
                </div>
            ))}
        </AutoplaySlider>
    );
};
Slider.propTypes = {
    height: PropTypes.string,
    urls: PropTypes.array,
    captions: PropTypes.array,
};

export default Slider;
