import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Img } from './';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexFlow: 'row wrap', // shorthand for flex-direction and flex-wrap
        justifyContent: 'space-between', // item alignment in flow direction
        alignItems: 'center', //item alignment perpendicular to flow direction
    },
    logoIdeal: {
        height: 52, // Van het iDeal logo op de ING iDeal betalingspagina
        padding: 15,
        // totaal 82 hoog zoals logoIncasso
    },
});

const LogoIdeal = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Img
                src="/media/logo-ideal.svg"
                alt="betalen met iDeal"
                className={classes.logoIdeal}
            />
            <div>iDeal</div>
        </div>
    );
};

export default LogoIdeal;
